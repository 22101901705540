<template>
    <div >
        <v-card class="white grey--text darken-4 text-xs-left" style="border-radius: 10px" v-if="shoppingBagTotalLength == 0" >
            <v-card-text style="font-size: 12px;">
                <p class="dark--text subheading pa-0 ma-0"><span> You don't have any items currently in your shopping bag...</span></p>
            </v-card-text>
        </v-card>

        <v-card color="white pa-2 elevation-4" style="border-radius: 10px" v-else>
            <v-data-table
                v-bind:headers="headers"
                :items="shopping_bag.items"
                :rows-per-page-items="rows_per_page"
                class="ma-0 pa-0 "
                >
                <template slot="headerCell" slot-scope="props">
                    <v-tooltip bottom>
                        <span slot="activator" class="ma-0 pa-0 ">
                        {{ props.header.text }}
                        </span>
                        <span>
                        {{ props.header.text }}
                        </span>
                    </v-tooltip>
                </template>
                <template slot="items" slot-scope="props">
                    <td class="ma-0 pa-2 " :to="'/market_place/items/'+props.item.id">{{ props.item.product.name }}</td>
                    <td class="ma-0 pa-0 pl-4 ">{{ props.item.product.price }}</td>
                    <td class="ma-0 pa-0 pl-4 ">
                        <a @click="incrementQuantity(props.item)"><i style="cursor: pointer" class="green--text fa-solid fa-circle-plus"> </i> </a>
                        {{ props.item.quantity }}
                        <a @click="decrementQuantity(props.item)" ><i style="cursor: pointer" class="green--text fa-solid fa-circle-minus"> </i></a>
                    </td>
                    <td class="ma-0 pt-0 pl-4">{{ getItemTotal(props.item) }}</td>
                    <td class="ma-0 pa-0 pr-4 text-xs-center">
                        <a @click="removeFromShoppingBag(props.item)"><i style="cursor: pointer; font-size: 18px" class="red--text fa-solid fa-circle-xmark"></i></a>
                    </td>
                </template>
                <template slot="footer">
                    <td colspan="100%">
                        <p class="pt-2 mb-1">Overall Summary: <b>{{ getShoppingBagTotal }} F CFA</b>, for {{ shoppingBagTotalLength }} items</p>
                        <div class="mb-2 mt-0 pt-0 text-xs-left">
                            <v-btn @click="payNow()" class="animate__animated animate__flash animate__delay-1s white--text" small round color="teal" >
                                <span> Pay Now</span>
                            </v-btn>
                        </div>

                    </td>
                </template>
            </v-data-table>
        </v-card>
    </div>
</template>

<script>
    import axios from 'axios'

    export default {
        data() {
            return {
                rows_per_page: [10, 25, 50, { text: "All", value: -1 }],
                headers: [
                {
                    text: 'Product',
                    align: 'left',
                    value: 'product'
                },
                {
                    text: 'Unit Price (F CFA)',
                    align: 'left',
                    value: 'unit_price'
                },
                { 
                    text: 'Quantity',
                    align: 'left',
                    value: 'quantity'
                },
                { 
                    text: 'Total (F CFA)',
                    align: 'left',
                    value: 'total'
                },
                { 
                    text: 'Remove Item',
                    align: 'right',
                    sortable: false,
                    value: 'remove'

                },
                ],
                shopping_bag: {
                    items: []
                },
            }
        },

        methods:{
            getItemTotal(item){
                return item.quantity*item.product.price
            },

            decrementQuantity(item){
                item.quantity -= 1
                if (item.quantity === 0){
                    this.removeFromShoppingBag(item)
                }
                this.updateShoppingBag()
            },
            incrementQuantity(item){
                item.quantity += 1
                this.updateShoppingBag()
            },
            removeFromShoppingBag(item){
                this.shopping_bag.items =this.shopping_bag.items.filter(i => i.product.id !== item.product.id)
                this.updateShoppingBag()
            },
            updateShoppingBag(){
                localStorage.setItem('shopping_bag', JSON.stringify(this.$store.state.shopping_bag))
            },

            async payNow(){
                if(this.shopping_bag.items.length === 0){
                    this.$store.commit('setSnackBarMessage', "Error: Your shopping bag is empty, please add items first.")
                    this.$store.commit('setSnackBarColor', "red darken-4")
                    this.$store.commit('activateSnackBar', true)
                    this.$refs.save_amount.focus()  // this causes a an auto fucos to the element
                }
                else {
                    if (this.$store.state.isAuthenticated){
                        this.$store.commit('setIsLoading', true)

                        await axios
                            .post('/api/v1/market_place/request/bill/transaction', JSON.stringify(this.shopping_bag), {headers: {'Content-Type': 'application/json'}})
                            .then(response => {
                                console.log(response.data)
                                this.$store.commit('setSnackBarMessage', response.data[0].text)
                                this.$store.commit('setSnackBarColor', response.data[0].color)
                                this.$store.commit('activateSnackBar', true)

                                var navigation = this.$router
                                if (response.data[0].valid){
                                    this.shopping_bag.items = []
                                    this.$store.commit('resetShoppingBag')                      
                                    navigation.push('/market_place/shopping_bag/items/pay_now/'+response.data[0].requested_order_id)  // redirect back to campaign
                                }
                            })
                            .catch(error => {
                                if (error.response){
                                    this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
                                    this.$store.commit('setSnackBarColor', "red darken-4")
                                    this.$store.commit('activateSnackBar', true)
                                }else{
                                    this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
                                    this.$store.commit('setSnackBarColor', "red darken-4")
                                    this.$store.commit('activateSnackBar', true)
                                }
                            })
                        this.$store.commit('setIsLoading', false)
                    }
                    else{
                        var navigation = this.$router

                        navigation.push({name: 'login', query: {to: this.$route.path} });
                        this.$store.commit('setSnackBarMessage', "Login is required: Please log into your account or create on now.")
                        this.$store.commit('setSnackBarColor', "red darken-4")
                        this.$store.commit('activateSnackBar', true)
                    }
                }

            },

        },

        mounted(){
            this.shopping_bag = this.$store.state.shopping_bag
            console.log(this.shopping_bag.items)
            this.$store.commit('setCloseURL', "/market_place")
        },

        computed: {
            shoppingBagTotalLength(){
                let totalLength = 0
                for(let i=0; i < this.shopping_bag.items.length; i++){
                    totalLength += this.shopping_bag.items[i].quantity
                }
                return totalLength
            },
            getShoppingBagTotal(){
                return this.shopping_bag.items.reduce((acc, curVal) => {
                    return acc += curVal.product.price * curVal.quantity
                }, 0) 
            },
        },


    }
</script>
